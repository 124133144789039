import './App.css';
import NavBar from './navbar';

function App() {
  return (
    <>
    <NavBar/>
     
    </>
  );
}

export default App;
